export default {
  "passcode": {
    "errors": {
      "0010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados faltanto"])},
      "0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail já está sendo utilizado!"])},
      "0012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao registrar o usuário!"])}
    }
  },
  "error": {
    "bankOrRepRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco ou representante é obrigatório"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail inválido"])},
    "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha deve ter pelo menos 7 caracteres"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As senhas não coincidem"])},
    "unexpectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro inesperado"])},
    "firstNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O primeiro nome é obrigatório"])},
    "lastNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O sobrenome é obrigatório"])},
    "departamentoRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O departamento é obrigatório"])},
    "cargoRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cargo é obrigatório"])},
    "passcodeExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu código de acesso expirou. Por favor, solicite um novo."])},
    "invalidPasscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de acesso fornecido é inválido. Verifique e tente novamente."])},
    "invalidCelular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de celular é inválido"])},
    "dateRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento é obrigatória."])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento fornecida é inválida."])},
    "dateInFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento não pode ser no futuro."])},
    "ageRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário precisa ter no mínimo 14 anos."])}
  },
  "tryAgainLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível fazer login. Tente novamente!"])},
  "newMarksTxts": {
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar status"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os status permitem que você categorize e identifique rapidamente as informações importantes em cada chamado. Seja para destacar prioridades, rastrear status ou simplesmente facilitar a organização"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar marcador"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "padrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador padrão"])},
    "turnPadrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar padrão"])},
    "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "padraoDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])},
    "kanbanAtualizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status atualizado com sucesso!"])}
  },
  "ssoOptions": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilite o seu acesso"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione uma opção abaixo, para criar sua conta "])}
  },
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "errorCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide o reCAPTCHA para continuar"])},
  "social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre com a sua rede social"])},
  "social_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie com a sua rede social"])},
  "0070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem permissão para acessar o Apptalk!"])},
  "0072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível criar sua conta apptalk, entre contato com o administrador!"])},
  "sso": {
    "login": {
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Login com ", _interpolate(_list(0)), " realizado com sucesso!"])},
      "successCreation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conta criada com ", _interpolate(_list(0)), " com sucesso!"])},
      "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conta ", _interpolate(_list(0)), " não encontrada!"])},
      "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível fazer login com a conta. Tente novamente"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta sincronizada com sucesso!"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Não foi possível sincronizar ", _interpolate(_list(0)), "!"])},
    "error1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email ", _interpolate(_list(0)), " já em uso!"])},
    "error7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não adicionado na plataforma Azure!"])}
  },
  "sync": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado como"])},
    "no1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular com"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar conta"])},
    "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquecer a conta"])},
    "delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conta ", _interpolate(_list(0)), " removida com sucesso!"])},
    "delete_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Erro ao remover conta ", _interpolate(_list(0)), "!"])}
  },
  "createNewTicketText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo ticket"])},
  "handleMarkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar marcadores"])},
  "recusarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusar transferência"])},
  "aceitarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar transferência"])},
  "transferNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de transferência realizado com sucesso!"])},
  "transferDenyNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência recusada com sucesso!"])},
  "waitTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamado em andamento, aguarde para mais atualizações"])},
  "waitTransferAdm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua requisição de transferência para o canal: "])},
  "foiRealizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" está sendo analisada"])},
  "waitingMod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket aguardando moderador"])},
  "waitingAprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferido: "])},
  "assumir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumir chamado"])},
  "assumirSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket aceito com sucesso"])},
  "transferRequestIndicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguardando aprovação de transferência: "])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo não pode estar vazio"])},
  "handleTicket": {
    "finalizarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
    "encerrarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar chamado"])},
    "transferirChamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
    "transferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para qual canal você deseja transferir o chamado?"])},
    "solicitarApoio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar apoio"])},
    "apoioText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para qual canal você deseja solicitar apoio?"])},
    "historico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
    "anotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotações"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoio"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva sua conclusão"])},
    "successNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotação enviada com sucesso"])},
    "transferMotivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique o motivo da transferência:"])},
    "denyTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dê um motivo"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicação"])},
    "motivation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicação:"])}
  },
  "chamados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tickets"])},
  "chamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ticket"])},
  "marcadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "avaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar"])},
  "arquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilize o Apptalk em seu dispositivo mobile"])},
  "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar app no dispositivo android"])},
  "Ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar app no dispositivo ios"])},
  "createNewTicket": {
    "selectAssunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha o assunto do ticket:"])},
    "selectAssunto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o assunto:"])},
    "outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
    "openNewTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir novo ticket"])},
    "needSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um assunto para continuar"])}
  },
  "createUser": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o código ou passcode"])},
    "validCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código validado"])},
    "completeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete os dados para criar sua conta"])}
  },
  "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados pessoais"])},
  "sobreMim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mim"])},
  "dadosEmpresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados da empresa"])},
  "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifique seu acesso conectando suas redes sociais ao nosso sistema"])},
  "actions": {
    "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status atualizado com sucesso!"])},
    "complaintCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denúncia realizada com sucesso!"])},
    "complaintNonCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível realizar denúncia!"])},
    "finishComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denuncia excluida com sucesso!"])},
    "notificationDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificação excluida com sucesso!"])},
    "notificationsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações excluidas com sucesso!"])},
    "profileEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil editado com sucesso!"])},
    "successComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário enviado!"])},
    "successCreatedTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket criado!"])},
    "successFinishTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket finalizado!"])},
    "successPostDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post excluido com sucesso!"])},
    "successPostSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post enviado com sucesso!"])},
    "successSendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem enviada!"])},
    "successTransferTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket transferido com sucesso!"])},
    "termoAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termo aceito com sucesso!"])},
    "sucessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token enviado com sucesso!"])},
    "successToken1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token verificado com sucesso!"])},
    "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha alterada com sucesso!"])},
    "sucessParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parecer adicionado com sucesso!"])},
    "successAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço avaliado com sucesso!"])},
    "successArquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket arquivado com sucesso!"])}
  },
  "noPossiblePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível atualizar senha!"])},
  "verificando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificando token..."])},
  "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token inválido!"])},
  "adminPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel administrador"])},
  "AdmOmbudsOpts": {
    "denounce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denúncia"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexo"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexos"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
    "noArch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa denúncia não possui nenhum anexo"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotações"])},
    "responseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva sua conclusão:"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva o que aconteceu:"])}
  },
  "apptalk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apptalk ® 2024"])},
  "noParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum comentário adicionado a esta denúncia"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muito satisfeito!"])},
  "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouco satisfeito!"])},
  "noPossibleAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível avaliar!"])},
  "biografia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografia"])},
  "changeCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de empresa"])},
  "changePass": {
    "cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "confirmId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua identidade"])},
    "findAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre a sua conta"])},
    "insertCell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira seu telefone para alterar sua senha"])},
    "insertCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o código de que te enviamos"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie uma nova senha"])},
    "req1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 caracter especial"])},
    "req2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 caracter maiúsculo"])},
    "req3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 caracter minúsculo"])},
    "req4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 caracter numérico"])},
    "sucessId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade confirmada com sucesso"])},
    "empresas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas"])},
    "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a empresa que deseja entrar"])}
  },
  "userdoNotExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não encontrado"])},
  "tokenTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde um instante para tentar novamente"])},
  "sendingToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando token..."])},
  "loadingChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando"])},
  "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "colab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaborador"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentário"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentários"])},
  "commentsUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
  "commentVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar"])},
  "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de comunicação"])},
  "communicationTexts": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
    "finish1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar ticket"])},
    "wantToFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja finalizar ticket?"])}
  },
  "countrys": {
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emirados Árabes Unidos"])},
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afeganistão"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antígua e Barbuda"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albânia"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armênia"])},
    "AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antilhas Holandesas"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antártida"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áustria"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrália"])},
    "AX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Aland"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijão"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bósnia e Herzegovina"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgária"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Bartolomeu"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolívia"])},
    "BQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Santo Eustáquio e Saba"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butão"])},
    "BV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Bouvet"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsuana"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cocos (Keeling)"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Democrática do Congo"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Centro-Africana"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República do Congo"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suíça"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa do Marfim"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camarões"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colômbia"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Christmas"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chipre"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Tcheca"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemanha"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibuti"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamarca"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Dominicana"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argélia"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equador"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estônia"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egito"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritreia"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanha"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiópia"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlândia"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Malvinas"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronésia"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Faroe"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["França"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabão"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reino Unido"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granada"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geórgia"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana Francesa"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gana"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gâmbia"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné Equatorial"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grécia"])},
    "GS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Geórgia do Sul e Sandwich do Sul"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiné-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana"])},
    "HM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Heard e Ilhas McDonald"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croácia"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungria"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésia"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha de Man"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índia"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território Britânico do Oceano Índico"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraque"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irã"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islândia"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itália"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordânia"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japão"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quênia"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quirguistão"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camboja"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Cristóvão e Névis"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreia do Norte"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreia do Sul"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cayman"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cazaquistão"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbano"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Lúcia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libéria"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituânia"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburgo"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letônia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbia"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marrocos"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mônaco"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldávia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marshall"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedônia do Norte"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mianmar (Birmânia)"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongólia"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marianas do Norte"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritânia"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurício"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivas"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malásia"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moçambique"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namíbia"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Caledônia"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níger"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Norfolk"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigéria"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicarágua"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países Baixos"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noruega"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Zelândia"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omã"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panamá"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Nova Guiné"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipinas"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquistão"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polônia"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Pitcairn"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguai"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catar"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunião"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romênia"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sérvia"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rússia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arábia Saudita"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Salomão"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudão"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suécia"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapura"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Helena"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovênia"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard e Jan Mayen"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslováquia"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serra Leoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somália"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudão do Sul"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé e Príncipe"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho (lado holandês)"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síria"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suazilândia"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Turks e Caicos"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chade"])},
    "TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territórios Franceses do Sul"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailândia"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajiquistão"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turcomenistão"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunísia"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquia"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad e Tobago"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzânia"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ucrânia"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estados Unidos"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguai"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbequistão"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade do Vaticano"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Vicente e Granadinas"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Britânicas"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Americanas"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnã"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis e Futuna"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iêmen"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["África do Sul"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zâmbia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbábue"])}
  },
  "createPostPlaceHldr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça uma publicação nesta editoria "])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dia"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dias"])},
  "denounceVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denunciar post"])},
  "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
  "entryManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar como gestor"])},
  "entryUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar como usuário"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falso"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "firstAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenho um código"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a senha?"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])},
  "genders": {
    "fem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminino"])},
    "masc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])}
  },
  "genero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gênero"])},
  "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para página principal"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página inicial"])},
  "complaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denúncias de publicações"])},
  "likeVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curtir"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro! Credenciais incorretas!"])},
  "loginText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Apptalk ajuda a sua empresa a se conectar com seus colaboradores de forma simples e inovadora."])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
  "mandatoryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mês"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" meses"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
  "noFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, parece que a página que você está procurando não foi encontrada. Isso pode ter acontecido porque o endereço da página foi digitado incorretamente ou porque a página foi removida."])},
  "noTicketsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum ticket criado nesse canal de comunicação"])},
  "notifications": {
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dê parabéns para os aniversariantes do dia!"])},
    "birthdayReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem novas mensagens de parabéns!"])},
    "noNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há novas notificações no momento 📭"])}
  },
  "ombudsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvidoria"])},
  "noFeedOnEditorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta editoria não possui nenhuma publicação"])},
  "noFeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, mas você não tem acesso a nenhuma postagem"])},
  "noFeed1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com o administrador"])},
  "ombudsmanTexts": {
    "acceptConduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estou de acordo com o código de conduta"])},
    "acceptTermo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estou de acordo com o termo de privacidade"])},
    "adm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
    "arquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivados"])},
    "ativos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicando aqui!"])},
    "conduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de conduta"])},
    "errorAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você precisa aceitar o termo para continuar"])},
    "firstMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensagens não vistas"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha o canal que deseja entrar"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deseja se identificar?"])},
    "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe o ocorrido"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "next1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "noOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua empresa não possui um canal de ouvidoria disponível"])},
    "resposta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta"])},
    "secondMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensagens em análise"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você também pode selecionar o arquivo"])},
    "sendAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado em: "])},
    "termo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termo de privacidade"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionário"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])}
  },
  "openArquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets arquivados"])},
  "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets abertos"])},
  "parecer": {
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusão"])},
    "noConclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa reclamação foi finalizada sem um parecer"])}
  },
  "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar senha"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
  "principalPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página principal"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])},
  "profile": {
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
    "departamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome completo"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])}
  },
  "profileEditError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível atualizar seu perfil"])},
  "userInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário cadastrado com sucesso!"])},
  "userInviteFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível cadastrar usuário!"])},
  "erroAoAtualizarUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao atualizar o usuário. Entre em contato com o administrador"])},
  "erroAoAtualizarUsuarioGenerico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houve um problema ao atualizar o usuário. Verifique os dados e tente novamente."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "videoOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo à etapa de conscientização!"])},
  "videoOmbuds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de prosseguir com sua denúncia, assista a esses vídeos informativos para entender melhor o contexto e as questões relacionadas ao assunto"])},
  "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mais"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdadeiro"])},
  "typeComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escreva um comentário..."])},
  "typeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escreva sua mensagem..."])},
  "userPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou telefone"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor: "])},
  "videoNoSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infelizmente seu navegador não suporta o player de vídeo"])},
  "vueTel": {
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do país"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex:"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
    "selecCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha o país"])}
  },
  "warnings": {
    "noContentPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o conteúdo do post"])},
    "uploadingPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazendo upload do post"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde o fim do carregamento"])}
  },
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semana"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semanas"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ano"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" anos"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
  "newCommunication": {
    "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva abaixo datalhadamente sobre o assunto, em breve alguém entrará em contato e você será notificado"])}
  }
}