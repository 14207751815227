<template>
  <!-- HEADER -->
  <router-view name="NavBar" :logo="representativeInfo.img" :routeName="$route.name"
    :representativeImg="representativeInfo.img" :allRepresentatives="this.allRepresentatives.length"
    @openMultiModal="openMultiModal" @openProfileModal="openProfileModal" :key="navBarKey" />

  <!--BODY-->
  <div class="BodyGrid" v-if="$route.name != 'login'">
    <router-view name="UserInfo" :fullName="this.userInfo.fullName" :userImage="this.userInfo.img"
      :routeName="$route.name" :userRank="this.userInfo.rank" :representativeImg="representativeInfo.img"
      :userRole="this.userInfo.role" :allRepresentatives="this.allRepresentatives" @openProfileModal="openProfileModal"
      :key="componentUserInfo" />

    <div class="renderArea">
      <router-view @changeChat="changeChatDisplay" @excludeChat="excludeChat" ref="mainViewRef" />

      <router-view name="AppCloud" />
    </div>

    <!-- CENTER MODALS -->
    <changeCompanyModal v-if="isModalVisible" @close="closeModal" :allRepresentatives="this.allRepresentatives"
      @selectCompany="selectCompany" />

    <ProfileModal v-if="modals.editProfile" id="editProfileModal" @closeEditProfile="hideEditProfile"
      @updateProfile="this.getStarterInfo()" :profileModalSelection="this.profileModalSelection" :key="profileModalKey">
    </ProfileModal>

    <ChangeProfileModal v-if="changeProfileVisible" />
  </div>

  <div class="login-view" v-else>
    <router-view @getInfo="getInfo" />
  </div>
</template>

<script>
import changeCompanyModal from "@/components/ChangeCompany.vue";
import ProfileModal from "@/components/defaultModals/profileModal.vue";
import CreatePost from "@/components/editorial/CreatePost.vue";
import Userinfo from "@/components/user/UserInfo.vue";
import UserService from "@/services/user.service";
import AuthService from "@/services/auth.service";
import Swiper from "@/components/editorial/Swiper.vue";
import Navbar from "@/components/Navbar.vue";
import { useAuth } from "@/store/auth.js";
import Post from "@/components/editorial/Post.vue";
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import ssoService from "@/services/sso.service";

import { AES, enc } from "crypto-js";

// FINGERPRINT 
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'

export default {
  components: {
    changeCompanyModal,
    ProfileModal,
    CreatePost,
    Userinfo,
    Navbar,
    Swiper,
    Post,
  },

  data() {
    return {
      count: 0,
      sidebarChannelKey: 0,
      feed: "",
      profileModalKey: 0,
      profileModalSelection: 1,
      dateType: "",
      moderator: [],
      editorias: "",
      allReactions: "",
      actualEditorial: "",
      isModalVisible: false,
      changeProfileVisible: false,
      selectedEditorial: "",
      allRepresentatives: "",
      createPostPermission: "",
      componentUserInfo: 1,
      userInfo: {
        img: "",
        fullName: "",
        firstName: "",
        id: "",
        role: "",
        rank: "",
      },
      representativeInfo: {
        img: "",
        name: "",
        id: "",
      },

      modals: {
        editProfile: false,
      },

      navBarKey: 1,

      admStatus: "",

      firstTimeUrl: true,
    }
  },

  beforeUpdate() {
    var encrypted = this.$route.query.v || "";

    if (encrypted != "") {
      this.processEncryptedString(encrypted);
    }

    if (this.$route.name !== "login" && this.count === 0) {
      this.getStarterInfo();
      this.count++;
    }
  },

  methods: {
    // Método para processar a string criptografada recebida
    processEncryptedString(encrypted) {
      var key = "RI8/qMyMBMdpf283rZ5+cpDsB8pnNDL0CkfUi6AlbKk=";

      encrypted = atob(encrypted);

      encrypted = JSON.parse(encrypted);

      const iv = enc.Base64.parse(encrypted.iv);

      const value = encrypted.value;
      key = enc.Base64.parse(key);

      var decrypted = AES.decrypt(value, key, {
        iv: iv,
      });
      decrypted = decrypted.toString(enc.Utf8);

      if (this.firstTimeUrl) {
        const myArray = decrypted.split("/");

        console.log("INFO", myArray)

        const status = myArray[0];
        const provider = myArray[1];

        if (status == "success") {
          const [token, providerID, platform, action] = myArray.slice(2, 6);

          this.handleSsoReturn(status, provider, providerID, token, platform, action);
        } else {
          const errorCode = myArray[3];
          const action = myArray[5];
          this.handleSsoReturn(status, provider, null, errorCode, null, action);
        }

        this.firstTimeUrl = false;
      }
    },

    handleSsoReturn(status, provider, providerID, token, platform, action) {
      console.log("Entrando em handleSsoReturn - Status:", status, "Provedor:", provider, "ID Provedor:", providerID, "Token:", token, "Plataforma:", platform, "Ação:", action);

      switch (status) {
        case "newsuccess":
          this.openSsoModal();
          toast.success(this.$t("sso.success"));
          break;
        default:
          this.openSsoModal();
          let errorMessage = "";

          switch (token) {
            case "0030":
              errorMessage = this.$t("sso.error1", [
                provider[0].toUpperCase() + provider.slice(1),
              ]);
              break;
            case "0077":
              errorMessage = this.$t("sso.error7");
              break;
            default:
              errorMessage = this.$t("sso.error", [
                provider[0].toUpperCase() + provider.slice(1),
              ]);
          }

          toast.error(errorMessage);
          break;
      }
    },


    getStarterInfo() {
      this.navBarKey += 1;
      this.$forceUpdate();

      UserService.getOwnInfo().then((data) => {
        if (!data.error) {
          this.userInfo = data;
          this.userInfo.id = data.id;
          this.userInfo.role = data.cargo;
          this.userInfo.rank = data.nivel_usuario;
          this.userInfo.fullName = data.nome;
          this.userInfo.representanteId = data.id_representante;
          this.userInfo.firstName = this.userInfo.fullName.split(" ")[0];
        }
      });

      this.componentUserInfo += 1;

      UserService.getRepresentativeInfo().then((data) => {
        if (!data.error) {
          this.representativeInfo.img = data.data.icon;
          this.representativeInfo.name = data.data.representative_name;
          this.representativeInfo.id = data.data.representative_id;
          this.$forceUpdate();
        }
      });

      UserService.getAllRepresentativeInfo().then((data) => {
        this.allRepresentatives = data.data;
        this.$forceUpdate();
      });

      this.$forceUpdate();
    },

    selectCompany(representativeId, newUserId) {
      const auth = useAuth();

      this.$router.push("/");

      AuthService.changeCompany(newUserId).then((data) => {
        if (!data.error) {
          auth.setToken(data.access_token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.access_token}`;
          window.location.reload();
        }
      });
    },

    getInfo() {
      this.getStarterInfo();
      this.loginMade = true;
    },

    openMultiModal() {
      this.isModalVisible = !this.isModalVisible;

      if (this.isModalVisible) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },

    closeModal() {
      this.isModalVisible = false;

      document.body.classList.remove("modal-open");
    },

    openProfileModal() {
      this.modals.editProfile = true;
      this.profileModalKey += 1;
      document.body.classList.add("modal-open");
    },

    openSsoModal() {
      this.modals.editProfile = true;
      this.profileModalKey += 1;
      this.profileModalSelection = 4;

      document.body.classList.add("modal-open");
    },

    hideEditProfile() {
      this.modals.editProfile = false;
      document.body.classList.remove("modal-open");
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --white: #ffff;
  --blue: #006ee6;
  --complementar-blue: #2b6cf8;
  --red: #e41e3f;
  --green: #40b927;
  --yellow: #f8b82b;
  --purple: #9300d7;
  --dark-yellow: #e1a421;
  --light-yellow: #fce2aa;
  --dark-grey: #c4c5c9;
  --darker-grey: #757575;
  --light-grey: #f0f0f0;
  --background-grey: #f0f2f7;
  --button-border-color: #e0e0e0;
  --container-box-shadow: #0000004a 0px 3px 8px 0px;
}

* {
  user-select: none;
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.backdrop_online {
  overflow: hidden;
}

.BodyGrid {
  margin-top: 60px;
  display: flex;
  position: relative;
}

.renderArea {
  width: 100%;
  height: 100%;
  margin-left: 280px;
}

.login-view {
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden;
}

html body {
  background-color: var(--background-grey) !important;
  margin: 0;
}

a#readmore {
  color: var(--yellow);
  text-decoration: none;
  font-size: 16px;
}

a#readmore:hover {
  color: var(--dark-yellow);
  text-decoration: underline;
  font-size: 16px;
  text-underline-offset: 3px;
}

.changeCompanyModal {
  background-color: var(--white);
}

@media only screen and (min-width: 1350px) {
  .grid {
    grid-template-columns: 280px 600px 280px;
    column-gap: 20px;
  }
}
</style>
